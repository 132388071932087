<template>
    <el-container>
        <el-header>
            <div class="project-title">石家庄市运宇物流有限公司
                <div class="project-title2">—— 查单系统</div>
            </div>
            <el-menu :default-active="activeIndex" mode="horizontal" class="tab"
                     background-color="#00706B" text-color="#fff"
                     active-text-color="#ffd04b" :router="true">
                <el-menu-item index="/taskList">运单列表</el-menu-item>
                <el-menu-item v-if="isAdmin" index="/userList">人员列表</el-menu-item>
            </el-menu>
            <div class="user">{{name}}</div>
            <router-link to="setting">
                <el-button type="primary" icon="el-icon-setting" circle/>
            </router-link>
            <router-link to="/login">
                <el-button type="primary" icon="el-icon-switch-button" circle/>
            </router-link>
        </el-header>
        <el-main class="el-main-tem">
            <router-view/>
        </el-main>
        <el-footer>
            <p>石家庄市运宇物流有限公司</p>
        </el-footer>
    </el-container>
</template>

<script>
    import myUtils from "@/utils/myUtils";
    import axiosUtils from "@/utils/axiosUtils";

    export default {
        name: "layout",
        data() {
            return {
                activeIndex: "/taskList",
                name: '',
                isAdmin: false,
            };
        },
        created() {
            const location = window.location.pathname.split('/');
            this.activeIndex = '/' + location[1];
            this.name = myUtils.getUserName()
            axiosUtils.get('isAdmin').then(res => {
                this.isAdmin = res
            })
        },
        methods: {

        }
    }
</script>

<style>
    .el-header {
        min-height: 90px;
        background-color: #00706B;
        color: white;
        display: flex;
        align-items: center;
    }

    .el-main {
        padding: 0;
        min-height: calc(100vh - 130px);
    }

    .el-footer {
        max-height: 40px;
        background-color: #00706B;
        color: white;
        display: flex;
        justify-content: center;
        align-items: center;
    }

    .el-menu-item {
        font-size: 18px;
        font-weight: bold;
    }

    .el-main-tem{
        padding: 20px;
    }

    .tab {
        width: 60%;
        margin: auto;
        padding-top: 30px;
    }

    /*img {*/
    /*    height: 70px;*/
    /*}*/

    .project-title {
        font-weight: bold;
        font-size: 25px;
    }

    .project-title2 {
        padding-top: 5px;
        font-weight: normal;
        font-size: 18px;
        text-align: right;
    }

    .user {
        color: white;
        padding-right: 10px;
    }

    .el-button.is-circle {
        padding: 5px;
    }

    button {
        background-color: #00706B;
        border-color: #00706B;
        font-size: 20px;
    }

    .el-upload-dragger {
        width: 100%;
    }
</style>